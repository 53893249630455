import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {icons, vw} from '../utilities/helpers'


const Wrap = styled.a`
    display: none;
    cursor: pointer;

    @media (max-width: 767px){
        display: block;
        position: fixed;
        right: ${vw(1.5)};
        bottom: ${vw(1.5)};
        height: ${vw(1.5)}; 
        width: ${vw(1.5)}; 
        background-image: url('${icons.hamburger}');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 100;

        &.true{
            background-image: url('${icons.close}');
        }
    }
`

export default() => {
    const obj = useRef(null)
    
    useEffect(() => {
        let visible = false
        const menu = document.getElementsByClassName('main-menu')
        const body = document.documentElement
        const overlay = document.getElementsByClassName('menu-overlay')
        
        obj.current.addEventListener('click', function(){
            if (!visible) {
                visible = true
                body.classList.add('u-oh')
                overlay[0].classList.add('is-active')
                menu[0].classList.add('is-visible')
                this.classList.add('true')
            } else {
                visible = false
                this.classList.remove('true')
                body.classList.remove('u-oh')
                overlay[0].classList.remove('is-active')
                menu[0].classList.remove('is-visible')
            }
        })
    })
    
    return (
        <Wrap ref={obj} className="burger"></Wrap>
    )
}
