import React from 'react'
import styled from 'styled-components'

const Wrap = styled.span`
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('${props => props.src}');
    display:block;
    width: 100%;
    height: 100%; 
`
export default (props) => {
    return (
        <Wrap src={props.src}/>
    )
}