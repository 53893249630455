import frontA from '../assets/images/placeholders/default-a.png'
import frontB from '../assets/images/placeholders/cut-c.png'

import backA from '../assets/images/placeholders/default-b.png'
import backB from '../assets/images/placeholders/cut-d.png'

import { icons } from '../utilities/helpers'

export const nav = [
    {
        'key': 1,
        'route': '/about',
        'title': 'About',
        'icon': icons.comment
    },
    {
        'key': 2,
        'route': '/program',
        'title': 'Program',
        'icon': icons.clock
    },
    {
        'key': 3,
        'route': '/speakers',
        'title': 'Speakers',
        'icon': icons.mic
    },
    {
        'key': 4,
        'route': '/exhibition',
        'title': 'Exhibition',
        'icon': icons.bulb
    },
    {
        'key': 5,
        'route': '/media',
        'title': 'Media',
        'icon': icons.media
    },
    {
        'key': 6,
        'route': '/register',
        'title': 'Register', 
        'icon': icons.thumb
    },
    {
        'key': 7,
        'route': '',
        'title': 'Menu',
        'icon': icons.hamburger,
        'class': 'hamburger'
    }
]

export const slideshow = [
    {
        'key': 1,
        'subtitle': '2019 IGD Theme',
        'title': 'Expanding Possibilities - Lifestyle, Innovation and Power',
        'caption': 'Come let’s talk about the sex issues affecting adolescents young people in Uganda today.',
        'action': 'learn more',
        'fg': frontA,
        'bg': backA
    },
    {
        'key': 2,
        'subtitle': '2019 IGD Theme',
        'title': 'Can absolute Power corrupt young leaders?',
        'caption': 'The Intergenerational Dialogue (IGD) is an annual one-day high-level advocacy platform that fosters structured conversations on the Sexual.',
        'action': 'learn more',
        'fg': frontB,
        'bg': backB
    }
]

var districts = "- Abim Adjumani Agago Alebtong Amolatar Amudat Amuria Amuru Apac Arua Budaka Bududa Bugiri Buhweju Buikwe Bukedea Bukomansimbi Bukwa Bulambuli Buliisa Bundibugyo Bushenyi Busia Butaleja Butambala Buvuma Buyende Dokolo Gomba Gulu Hoima Ibanda Iganga Isingiro Jinja Kaabong Kabale Kabarole Kaberamaido Kalangala Kaliro Kalungu Kampala Kamuli Kamwenge Kanungu Kapchorwa Kasese Katakwi Kayunga Kibaale Kiboga Kibuku Kiruhura Kiryandongo Kisoro Kitgum Koboko Kole Kotido Kumi Kween Kyankwanzi Kyegegwa Kyenjojo Lamwo Lira Luuka Luweero Lwengo Lyantonde Manafwa Maracha Masaka Masindi Mayuge Mbale Mbarara Mitooma Mityana Moroto Moyo Mpigi Mubende Mukono Nakapiripirit Nakaseke Nakasongola Namayingo Namutumba Napak Nebbi Ngora Ntoroko Ntungamo Nwoya Otuke Oyam Pader Pallisa Rakai Rubirizi Rukungiri Sembabule Serere Sheema Sironko Soroti Tororo Wakiso Yumbe Zombo"

export const districtList = districts.split(" ")

export const countryList = ["Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas"
    , "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bosnia & Herzegovina", "Botswana", "Brazil", "British Virgin Islands"
    , "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon", "Canada", "Cape Verde", "Cayman Islands", "Chad", "Chile", "China", "Colombia", "Congo", "Cook Islands", "Costa Rica"
    , "Cote D Ivoire", "Croatia", "Cruise Ship", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea"
    , "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", "Fiji", "Finland", "France", "French Polynesia", "French West Indies", "Gabon", "Gambia", "Georgia", "Germany", "Ghana"
    , "Gibraltar", "Greece", "Greenland", "Grenada", "Guam", "Guatemala", "Guernsey", "Guinea", "Guinea Bissau", "Guyana", "Haiti", "Honduras", "Hong Kong", "Hungary", "Iceland", "India"
    , "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jersey", "Jordan", "Kazakhstan", "Kenya", "Kuwait", "Kyrgyz Republic", "Laos", "Latvia"
    , "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Mauritania"
    , "Mauritius", "Mexico", "Moldova", "Monaco", "Mongolia", "Montenegro", "Montserrat", "Morocco", "Mozambique", "Namibia", "Nepal", "Netherlands", "Netherlands Antilles", "New Caledonia"
    , "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman", "Pakistan", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal"
    , "Puerto Rico", "Qatar", "Reunion", "Romania", "Russia", "Rwanda", "Saint Pierre & Miquelon", "Samoa", "San Marino", "Satellite", "Saudi Arabia", "Senegal", "Serbia", "Seychelles"
    , "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "South Africa", "South Korea", "Spain", "Sri Lanka", "St Kitts & Nevis", "St Lucia", "St Vincent", "St. Lucia", "Sudan"
    , "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Timor L'Este", "Togo", "Tonga", "Trinidad & Tobago", "Tunisia"
    , "Turkey", "Turkmenistan", "Turks & Caicos", "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "United States Minor Outlying Islands", "Uruguay"
    , "Uzbekistan", "Venezuela", "Vietnam", "Virgin Islands (US)", "Yemen", "Zambia", "Zimbabwe"]