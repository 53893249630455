import styled, {css} from 'styled-components'

export default styled.span`

    display:block;

    ${props => props.xlarge && css`
        font-size: 2.75vw;
        font-weight: 700;
    `}

    ${props => props.large && css`
        font-size: 1.5vw;
    `}

    ${props => props.med && css`
        font-size: 1.25vw;
    `}

    ${props => props.normal && css`
        font-size: 1.125vw;
    `}

    ${props => props.small && css`
        font-size: 0.9vw;
        line-height: 1.3;
    `}

    ${props => props.tiny && css`
        font-size: 0.75vw;
    `}

    @media (max-width: 1023px){
        
        ${props => props.xlarge && css`
            font-size: 3.75vw;
        `}

        ${props => props.large && css`
            font-size: 2.5vw;
        `}

        ${props => props.med && css`
            font-size: 2vw;
        `}

        ${props => props.normal && css`
            font-size: 1.75vw;
        `}

        ${props => props.small && css`
            font-size: 1.5vw;
        `}

        ${props => props.tiny && css`
            font-size: 1vw;
        `}
    }


    @media (max-width: 767px){
        
        ${props => props.xlarge && css`
            font-size: 6.75vw;
        `}

        ${props => props.large && css`
            font-size: 5.5vw;
        `}

        ${props => props.med && css`
            font-size: 5.25vw;
        `}

        ${props => props.normal && css`
            font-size: 4.125vw;
        `}

        ${props => props.small && css`
            font-size: 3.9vw;
        `}

        ${props => props.tiny && css`
            font-size: 2.75vw;
        `}
    }
`