import React, {useEffect } from 'react'
import styled, {css} from 'styled-components'

import { vw, icons, colors, rove, easings} from '../utilities/helpers'

import Icon from './icon'
import Bit from './bit'
import Line from './line'

const Box = styled.div`
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  z-index: 10;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  background-color: rgba(10, 5, 25, 0.97);

  &.is-visible{
    opacity: 1;
    visibility: visible;
  }

  ${Line} {
    position: absolute; 
    top: 50%;
    left: 50%;

    &.is-hidden{
      display: none;
    }    
    
    animation: ${rove} 1s infinite both;
  }

  ${props => props.inline && css`
    opacity: 1;
    visibility: visible;
    position: static;

    ${Close} {
      display: none;
    }

    ${Wrap}{
      width: 75%;
    }
  `}

  @media (max-width: 767px) {
    align-items: flex-start;
    padding-top: ${vw(3)}
  }
`

const Wrap = styled.div`
  width: 80%;
  position: relative;
`

const Close = styled.a`
  position: absolute; 
  width: ${vw(0.5)};
  height: ${vw(0.5)};
  top: 0;
  right: 0;
  z-index: 2;
  background-color: ${colors.purple};
  padding: ${vw(0.25)};
  border-radius: 100px;

  transition: opacity 0.3s;
  :hover{
      opacity: 0.7
  }

  @media (max-width: 767px){
    width: ${vw(2)};
    height: ${vw(2)}; 

    span {
      background-size: 50%;
    }
  }
`

const Live = styled.div`
  width: ${vw(2)};
  height: ${vw(1)};
  background-image: url(${icons.live});
  background-size: contain;
  background-repeat: no-repeat;
  margin-top: ${vw(0.5)};
`
const Media = styled.div`
    position: relative; 
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;

    transition: transform 1.25s ${easings.easeOutQuint}, opacity 1.25s;
    transform: translate3d(0, 50%, 0) scale3d(0.85,0.85,0.85);
    opacity: 0;

    .is-visible & {
      transform: none;
      opacity: 1;
    }

    img{
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    h3 {
      color: ${colors.yellow};
      text-transform: uppercase; 
      padding-bottom: ${vw(0.25)};
    }

    ul {
      list-style: disc inside;
      padding-top: ${vw(0.25)};

      li {
        padding-bottom: ${vw(0.125)};
      }
    }

    .album, iframe{
      position: absolute;
		  top: 0;
		  left: 0;
		  width: 100%;
		  height: 100%;
    }

    .album-slide{
      height: 100%;
      display: none;
    }

    .album-button{
      text-transform: uppercase;
      text-align: center;
      font-size: 1vw;
      display: inline-block;
      background-color: ${colors.green};
      color: #000;
      border-radius: 120px;
      padding: ${vw(0.15)} ${vw(0.25)};
      font-weight: bold;
      border:none;
      position: absolute; 
      z-index: 1;
      top: 50%;
      cursor: pointer;

      &.prev {
        left: ${vw(1)}
      }
      &.next{
        right: ${vw(1)}
      }
    }

    @media (max-width: 767px){
      height:80vh;
      padding-bottom: 0;

      .album-button{
        font-size: 4vw;
        padding: ${vw(0.5)} ${vw(1)};
      }

      .album-photos {
        height: 100%;
      }
      
    }
`

export default(props) => {

    useEffect(() => {
      const body = document.documentElement
      const close = document.getElementsByClassName("lightbox-close")
      const lightbox = document.getElementsByClassName("lightbox")
      const content = document.getElementsByClassName("lightbox-media")
      const preloader = document.querySelector('.video-preloader')

      const closeLightbox = (e) => {
        e.preventDefault()
        body.classList.remove('u-oh')
        preloader.classList.add('is-hidden')

        lightbox[0].classList.remove('is-visible')
        content[0].innerHTML = ''
      }
      close[0].addEventListener("click", closeLightbox)

      return () => {
        close[0].removeEventListener("scroll", closeLightbox)
      }
    })

    return(
        <Box className="lightbox" {...props}>
            <Line className="video-preloader is-hidden" />            
            
            <Bit ring top="10%" right="10%"/>
            <Bit cross top="50%" left="5%"/>
            <Bit ring top="85%" right="5%"/>

            <Wrap>
              <Close className="lightbox-close" href="#">
                  <Icon src={icons.close} />
              </Close>
              <Live />
              <Media className="lightbox-media">
                {props.src && 
                  <iframe src={props.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                }
              </Media>
            </Wrap>
        </Box>
    )
}