import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

import {vw} from '../utilities/helpers'

if (typeof window !== 'undefined') {
    var SineWaves = require('sine-waves')
}

const Box = styled.div`
    position: fixed;
    bottom: ${vw(-1)};
    width: 100vw;
    height: 30vh;
    z-index: -100;
    opacity: 0.3;

    transition: opacity 1.5s;

    &.is-active{
        opacity: 1;
    }
`
export default() => {
    const obj = useRef(null)

    useEffect(() => {
        var amp = window.innerHeight /5
        var waves = true

        waves = new SineWaves({
            el: document.getElementById('waves'),

            speed: 4,

            width: window.innerWidth,

            height: obj.current.clientHeight,

            ease: 'SineInOut',

            wavesWidth: '100%',
            wavesHeight: '100%',

            waves: [
                {
                    timeModifier: 4,
                    lineWidth: 1,
                    amplitude: -25,
                    wavelength: 25
                },
                {
                    timeModifier: 1,
                    lineWidth: 1,
                    amplitude: -100,
                    wavelength: 100
                },
                {
                    timeModifier: 0.25,
                    lineWidth: 2,
                    amplitude: -1 * amp,
                    wavelength: amp
                }
            ],

            // Called on window resize
            resizeEvent: function () {
                var gradient = this.ctx.createLinearGradient(0, 0, this.width, 0);
                gradient.addColorStop(0, "rgba(23, 210, 168, 0.2)");
                gradient.addColorStop(0.5, "rgba(255, 255, 255, 0.5)");
                gradient.addColorStop(1, "rgba(23, 210, 168, 0.2)");

                var index = -1;
                var length = this.waves.length;
                while (++index < length) {
                    this.waves[index].strokeStyle = gradient;
                }

                // Clean Up
                index = void 0;
                length = void 0;
                gradient = void 0;
            }
        })
    }, [])

    return (
        <Box ref={obj} className="waveBox">
            <canvas id="waves"></canvas>
        </Box>
    )
}