import styled from 'styled-components'
import logo from '../assets/images/igd-logo.svg'
import logoMini from '../assets/images/igd-logo-mini.svg'
import { vw } from '../utilities/helpers'

export default styled.div`
  display: inline-block;
  float: left;

  a{
    width: ${vw(3)};
    height: ${vw(1)};
    background-image: url('${logo}');
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;

    .mini & {
      background-image: url('${logoMini}');
    }
  }

  @media (max-width: 1024px) {
    a {
      width: ${vw(1.25)};
      height: ${vw(1.25)};
      background-image: url('${logoMini}');
    }
  }


  @media (max-width: 767px) {
    a {
      width: ${vw(3)};
      height: ${vw(3)};
      background-image: url('${logoMini}');
      margin: ${vw(1.5)}; 
    }
  }
`