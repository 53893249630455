import React, { useEffect, useState, useRef } from 'react'
import styled, {css, keyframes} from 'styled-components'

import {vw} from '../utilities/helpers'

import loop from '../assets/audio/powerquotes.mp3'
import logo from '../assets/images/rahu-logo.svg'

export const pulse = keyframes`
    0% {
      transform: scale3d(1,0,1);
    }
    50% {
      transform: scale3d(1,1,1);
    }
    100% {
      transform: scale3d(1,0,1);
    }
`

const Box = styled.a`
  display: block;
  width: ${vw(0.4)};
  height: ${vw(0.4)};
  position: fixed;
  cursor:pointer;
  bottom: ${vw(0.75)};
  z-index: 1;

  ${props => props.left && css`
    left: ${vw(0.75)};
    
    background-image: url('${logo}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

  `}

  ${props => props.right && css`
    right: ${vw(0.75)};
  `}

  @media (max-width: 767px){
    width: ${vw(1.5)};
    height: ${vw(1.5)};

    ${props => props.left && css`
      left: auto;
      right: ${vw(1.5)};
      top: ${vw(1.5)};
      width: ${vw(2)};
      height: ${vw(2)};
    `}

    ${props => props.right && css`
      bottom: ${vw(1.5)};
      left: ${vw(1.5)};
      right: auto;
    `}
  }
`

const Bars = styled.div` 
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    transition: opacity 1s;

    &.true {
      opacity: 1;
    }

    span {
      width: 20%;
      bottom: 0;
      height: 75%;
      display: inline-block;
      background-color: white;
      transform-origin: 0 100%;

      animation: ${pulse} 1s linear infinite both; 

      :nth-child(1){
        animation-delay: 0.1s;
        animation-duration: 1s;
      }

      :nth-child(2){
        animation-duration: 0.75s;
      }

      :nth-child(3){
        animation-delay:0.5;
        animation-duration: 1.25s;
      }
    }
`

export default() => {
  const [isPlaying, togglePlay] = useState(false)
  const toggle = useRef(null)

  useEffect(() => {
    const audio = document.getElementById('powerquotes')
    const waves = document.querySelector('.waveBox')
    
    isPlaying  ? audio.play() : audio.pause()
    isPlaying ? waves.classList.add('is-active') : waves.classList.remove('is-active')

  })

  return (
    <>
      <audio autoPlay loop id="powerquotes">
        <source src={loop} type="audio/mpeg"></source>
      </audio>

      <Box left href="http://reachahand.org" target="_blank" rel="noopener noreferrer"/>
      
      <Box right onClick={() => togglePlay(!isPlaying)}>
        <Bars ref={toggle} className={isPlaying}>
          <span></span>
          <span></span>
          <span></span>
        </Bars>
      </Box>
    </>
  )
}