import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import {vw} from '../utilities/helpers'

const Header = styled.header`
  position: fixed;
  width: ${vw(20.5)};
  top: ${vw(0.75)};
  left: ${vw(0.75)};
  z-index: 6;
  opacity: 0;
  transform: translate3d(0, -50%, 0);
  
  transition: transform 1s, opacity 1s;
  transition-delay: 0.25s;

  &.is-loaded{
      opacity: 1;
      transform:translate3d(0,0,0);
  }

  @media (max-width: 767px){
      top: 0;
      left: 0;
      width: auto;
  }
`

export default(props) => {
    const [visibility, setVisibility] = useState('')
    
    useEffect(()=> {
        setVisibility('is-loaded')
    }, [])

    return (
        <Header className={'page-logo '+visibility}>
            {props.children}
        </Header>
    )
}