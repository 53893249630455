import styled from 'styled-components'
import {vw} from '../utilities/helpers'
import zig from '../assets/images/zig-yellow.svg'
import zigRed from '../assets/images/zig-red.svg'

export default styled.span`
    display: block;
    background-size: contain;
    background-repeat: repeat-x;
    height: ${vw(0.25)};
    width: ${vw(1.25)};
    background-image: url('${props => props.red ? zigRed : zig}');
    margin-top: ${vw(0.75)};

    @media (max-width: 1023px){
        height: ${vw(0.3)};
        width: ${vw(1.8)};
        margin-top: ${vw(0.75)};
    }

    @media (max-width: 767px) {
        height: ${vw(0.5)};
        width: 50%;
        margin-top: ${vw(2)};
    }
`