import React from 'react'

import {keyframes} from 'styled-components'
import patchGreen from '../assets/images/pattern-green.jpg'
import patchPink from '../assets/images/pattern-pink.jpg'
import patchWarm from '../assets/images/pattern-warm.jpg'
import arrow from '../assets/images/arrow-ltr-lime.svg'

import play from '../assets/images/icons/play.svg'
import doc from '../assets/images/icons/doc.svg'
import heart from '../assets/images/icons/heart.svg'
import comment from '../assets/images/icons/comment.svg'
import clock from '../assets/images/icons/clock.svg'
import mic from '../assets/images/icons/mic.svg'
import bulb from '../assets/images/icons/bulb.svg'
import thumb from '../assets/images/icons/thumb.svg'
import hamburger from '../assets/images/icons/menu.svg'
import media from '../assets/images/icons/media.svg'
import close from '../assets/images/icons/close.svg'
import camera from '../assets/images/icons/camera.svg'
import location from '../assets/images/icons/location.svg'
import clockoutline from '../assets/images/icons/clock-outline.svg'
import twitter from '../assets/images/twitter.svg'
import fb from '../assets/images/fb.svg'
import ig from '../assets/images/ig.svg'
import yt from '../assets/images/icons/youtube.svg'
import whatsapp from '../assets/images/whatsapp.svg'
import live from '../assets/images/live.svg'


export const base = 60

export const units = {
    'base': 60,
    'med': 30,
    'small': 15,
    'tiny': 6
}

export const paths = {
    'a': "M1172.8,706.3c16.5-16.5,31.3-33.5,44.6-51c54.4-71.7,82.2-152.3,83.6-241.7c1.5-111.3-37.9-207.3-118.5-287.9 C1098.5,41.9,1000.1,0,887.3,0c-92.3,0-174.8,28-247.6,84c-16.3,12.5-32.1,26.5-47.5,41.8L0,718h1161.1L1172.8,706.3z",
    'b': "M128.2,11.7c-16.5,16.5-31.3,33.5-44.6,51C29.2,134.4,1.4,214.9,0,304.4C-1.5,415.6,38,511.6,118.6,592.2 C202.5,676.1,300.9,718,413.7,718c92.3,0,174.8-28,247.6-84c16.3-12.5,32.1-26.5,47.5-41.8L1301,0L139.9,0L128.2,11.7z",
    'c': "M1172.8,11.7c16.5,16.5,31.3,33.5,44.6,51c54.4,71.7,82.2,152.3,83.6,241.7c1.5,111.3-37.9,207.3-118.5,287.9 C1098.5,676.1,1000.1,718,887.3,718c-92.3,0-174.8-28-247.6-84c-16.3-12.5-32.1-26.5-47.5-41.8L0,0h1161.1L1172.8,11.7z",
    'd': "M128.2,706.3c-16.5-16.5-31.3-33.5-44.6-51C29.2,583.6,1.4,503.1,0,413.6C-1.5,302.4,38,206.4,118.6,125.8 C202.5,41.9,300.9,0,413.7,0C506,0,588.5,28,661.3,84c16.3,12.5,32.1,26.5,47.5,41.8L1301,718H139.9L128.2,706.3z",
    'e': "M0,704.9V10C0,1.1,10.8-3.3,17.1,2.9l703,702H0z",
    'f': "M0,0v690.1c0,10.7,12.9,16,20.5,8.5L720.1,0H0z",
    'g': "M720.1,0v699.7c0,7.1-8.6,10.7-13.7,5.7L0,0L720.1,0z",
    'h': "M720.1,704.9V10c0-8.9-10.8-13.4-17.1-7.1L0,704.9H720.1z",
    'j': "M787.3,358.4L1145.7,0H351.3L5,346.4c-6.6,6.6-6.6,17.4,0,24L353.6,719h386.2h408.2L787.3,358.4z",
    'k': "M794.4,0H0l360.1,360.1L1.1,719h794.4l350.4-350.4c4.7-4.7,4.7-12.3,0-17L794.4,0z"
}

export const patterns = {
    'green': patchGreen,
    'pink': patchPink,
    'warm': patchWarm
}

export const icons = {
    'play': play,
    'doc': doc,
    'arrow': arrow,
    'heart': heart,
    'comment': comment,
    'clock': clock,
    'mic': mic,
    'bulb': bulb,
    'media': media,
    'thumb': thumb,
    'hamburger': hamburger,
    'close': close,
    'location': location,
    'clockoutline': clockoutline,
    'camera': camera,
    'fb': fb,
    'tw': twitter,
    'ig': ig,
    'whatsapp': whatsapp,
    'yt': yt,
    'live': live
}

export function createOptions(item, index) {
    return (
        <option value={item} key={index}>{item}</option>
    )
}

export function em(a){
    return a/16 + 'em'
}

export function percent(a) {
    return (a * 100) + '%'
}

export function vw(a) {
    return (base * a * 100/1320) + 'vw'
}

export function genID() {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < 5; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
}

export function count(ele, start, end, duration, unit) {
    if (typeof window !== 'undefined') {     
        
        if(ele !== null){
            var range = end - start
            var minTimer = 50
            var stepTime = Math.abs(Math.floor(duration / range))

            stepTime = Math.max(stepTime, minTimer)

            var startTime = new Date().getTime()
            var endTime = startTime + duration
            var timer

            function run() {
                var now = new Date().getTime()
                var remaining = Math.max((endTime - now) / duration, 0)
                var value = Math.round(end - (remaining * range));

                if (!isNaN(value)) {
                    ele.innerHTML = value + unit
                } else {
                    clearInterval(timer)
                }

                if (value === end) {
                    clearInterval(timer)
                }
            }

            timer = setInterval(run, stepTime)
            run()
        }
    }
}

export const contain = `
    background-size: contain;
    background-repeat: no-repeat;
`

export const colors = {
    'purple': '#F437BA',
    'green': '#4BEB90',
    'pink': '#F437BA',
    'yellow': '#fec451'
}

export const spin = keyframes`
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg)
    }
`

export const fadeInUp = keyframes`
    from {
        transform: translate3d(0, 50%, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0%, 0);
        opacity: 1;
    }
`

export const scaleInUp = keyframes`
    from {
        transform: translate3d(0, 50%, 0) scale3d(0.5,0.5,0.5);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0%, 0) scale3d(1,1,1);
        opacity: 1;
    }
`

export const fadeInDown = keyframes`
    from {
        transform: translate3d(0, -50%, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0%, 0);
        opacity: 1;
    }
`

export const scaleIn = keyframes`
    0% {
        transform: scale3d(1, 1, 1);
    }
    50% {
        transform: scale3d(1.3, 1.3, 1.3);
    }

    100%{
       transform: scale3d(1, 1, 1); 
    }
`

export const scaleInBig = keyframes`
    0% {
        transform: scale3d(1.5, 1.5, 1.5);
    }
    50% {
        transform: scale3d(1.7, 1.7, 1.7);
    }

    100%{
       transform: scale3d(1.5, 1.5, 1.5); 
    }
`

export const scaleOut = keyframes`
    0% {
        transform: scale3d(1, 1, 1) rotate(0);
    }
    50% {
        transform: scale3d(0.9, 0.9, 0.9) rotate(20deg);
    }

    100%{
       transform: scale3d(1, 1, 1) rotate(0); 
    }
`

export const skewInUp = keyframes`
    from {
        transform: skewY(10deg) translate3d(0, 40%, 0);
        opacity: 0;
    }
    to {
        transform: skewY(0) translate3d(0, 0%, 0);
        opacity: 1;
    }
`

export const fadeInLeft = keyframes`
    from {
        transform: translate3d(10%, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
`

export const fadeInRight = keyframes`
    from {
        transform: translate3d(-10%, 0, 0);
        opacity: 0;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
`

export const fadeOut = keyframes`
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
`

export const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const glow = keyframes`
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`

export const rove = keyframes`
    0% {
        transform: translate3d(0%, 0, 0);
    }
    50% {
        transform: translate3d(100%, 0, 0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0%, 0, 0);
    }
`

export const easings = {
    easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
    easeOutQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)'
}