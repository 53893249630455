import styled, {css} from 'styled-components'

import { vw, spin, glow, scaleIn} from '../utilities/helpers'

import ring from '../assets/images/ring.svg'
import cross from '../assets/images/cross.svg'
import zig from '../assets/images/zig-red.svg'
import arrow from '../assets/images/arrow.svg'


export default styled.span`
    position: absolute; 
    width: ${vw(0.175)};
    height: ${vw(0.175)};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.75;

    left: ${props => props.left ? props.left : 'auto'};
    right: ${props => props.right ? props.right : 'auto'};
    top: ${props => props.top ? props.top : 'auto'};
    z-index: ${props => props.index ? props.index : 2};

    ${props => props.zig && css`
        width: ${vw(1)};
        background-repeat: repeat-x;
        background-image: url(${zig});
    `}

    ${props => props.ring && css`
        background-image: url(${ring});
        animation: ${scaleIn} 1.5s linear infinite both;
    `}

    ${props => props.cross && css`
        background-image: url(${cross});
    `}

    ${props => props.arrow && css`
        background-image: url(${arrow});
        animation: ${glow} 2s linear infinite both;
    `}

    ${props => props.cross && css`
        animation: ${spin} 3s linear infinite both;
    `}

    @media (max-width: 1023px){
        width: ${vw(0.3)};
        height: ${vw(0.3)};
    }

    @media (max-width: 767px){
        width: 12px;
        height: 12px;
    }
`