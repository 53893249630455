import React from "react"
import { useStaticQuery, graphql} from 'gatsby'
import styled from 'styled-components'

import Waves from '../components/waves'
import Logo from '../components/logo'
import Menu from '../components/menu'
import Venue from '../components/venue'
import Audio from '../components/audio'
import Lightbox from '../components/lightbox'
import Header from '../components/header'
import Link from '../components/link'
import Burger from '../components/burger'
import Transition from '../components/transition'
import Text from '../components/text'

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    easing: 'easeInOutCubic'
  })
}

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(12, 6, 18, 0.95);
  width: 100vw;
  height: 100vh;
  z-index: 5;

  transition: opacity 1s, visibility 1s;
  opacity: 0;
  visibility: hidden;

  &.is-active{
    opacity: 1;
    visibility: visible;
  }

  @media (max-width: 767px){
    background: rgba(12,6,18,1);
    transition: none;
  }
`


export default(props) => {

   const data = useStaticQuery(graphql`
    query FrameQuery {
      allPrismicHome {
        edges {
          node {
            data {
              event_hashtag {
                text
              }
              event_venue {
                text
              }
              event_date(formatString: "DD MMMM YYYY")
              contact_email {
                text
              }
              contact_name {
                text
              }
              contact_phone {
                text
              }
              contact_title {
                text
              }
            }
          }
        }
      }
    }
  `)

  const node = data.allPrismicHome.edges[0].node.data

  return (
    <>
      <Header>
        <Logo>
          <Link to="/"></Link>
        </Logo>
        <Venue className="header-venue">
          <div>
            <Text small>{node.event_date}</Text>
            <Text small>{node.event_venue.text}</Text>
          </div>
        </Venue>
        <Menu/>
      </Header>
      <Transition location={props.location}>
        {props.children}
      </Transition>
      <Audio/>
      <Overlay className="menu-overlay"/>
      <Lightbox/>
      <Burger />
      <Waves />
    </>
  )
}