import styled from 'styled-components'
import {vw} from '../utilities/helpers'

export default styled.a`
  font-weight: bold;
  display: flex;
  align-items: center;
  height: ${vw(1)};
  width: ${vw(4)};
  padding-left: ${vw(1)};
  float: left;
  transition: opacity 0.5s;

  &.is-hidden{
    opacity: 0;
  }

  @media (max-width: 767px){
    display: none;
  }
`