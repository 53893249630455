import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'

import { vw, fadeInUp, easings} from '../utilities/helpers'
import {nav} from '../utilities/data'

import Link from './link'
import Text from './text'

const Menu = styled.nav`
    float: right;
    display: flex;
    align-items: center;
    height: ${vw(1)};

    &.is-hidden{
        > div{
            transform: translateY(-100%);
            opacity: 0;

            :last-child{
                transform: none;
                opacity: 1;
            }
        }
    }

    button{
        background:none;
        border: none;
        font-family: 'Quicksand',sans-serif;
        font-weight: 700;
        color: #fff;
        outline: none;
    }

    button, a{
        display: block;
        position: relative;

        &.is-active, &:hover{
            color: #000;

            div{
                transform: scale(1.5);
            }
        }
    }

    &.is-visible{
        position: fixed;
        width: 100vw;
        height: 100vh;
        animation: ${fadeInUp} 1.5s ${easings.easeOutQuint} both;

        > div{
            opacity: 1
        }
    }

    @media (max-width: 767px){
        top: 0;
        left: 0;
        flex-direction: column;
        justify-content: center;

        > div {
           opacity: 0;
           transition: none;
           display: block;
        }
    }
`

const Item = styled.div`
    text-align: center;
    display: inline-block;
    margin-left: ${vw(0.4)};
    transition: transform 0.5s, opacity 0.35s;
    transition-delay: ${props => (props.offset/30)}s;

    :last-child{
        position: absolute;
        right: 0;
        transform: translateY(100%);
        opacity: 0;
    }

    svg{
        fill: #fff;
    }
    @media (max-width: 1023px){
        margin-left: ${vw(0.7)};
    }

    @media (max-width: 767px){
        display: block;
        text-align: center;
        margin: 0;
        padding: 12px 0;

        :last-child{
            display: none
        }
    }
`

const Label = styled.i`
    display: block;
    
    span {
        font-weight: 700;
        text-align:center;
        padding-top: ${vw(0.1)};
    }
`

const Icon = styled.span`
    display: block;
    margin: 0 auto;
    width: ${vw(0.35)};
    height: ${vw(0.35)};
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('${props => props.icon}');

    ${props => props.hamburger && css`
        cursor: pointer;
        width: ${vw(0.5)};
        height: ${vw(0.5)};
    `}

    @media (max-width: 1023px){
        width: ${vw(0.4)};
        height: ${vw(0.4)};

        ${props => props.hamburger && css`
            width: ${vw(0.7)};
            height: ${vw(0.7)};
        `}
    }

    @media (max-width: 767px){
        width: 24px;
        height: 24px;
    }
`

const State = styled.div`
    position: absolute;
    top: ${vw(0.1)};
    z-index: -1;
    transition: transform 0.5s;
    transform: scale(0);
`

function createItem(item) {
    return (
        <Item key={item.key} offset={item.key}>
            {item.class==='hamburger' && 
                <button className="hamburger">
                    <Icon icon={item.icon} hamburger></Icon>
                    <State>
                        <svg x="0px" y="0px" width="95%" height="95%" viewBox="0 0 500 500">
                            <path d="M5.5,219C29.2,106.8,130.4-1.3,250.5,0c165.3,1.8,283.9,209.8,239,339c-25.5,73.3-99.1,108.7-131,124 c-25.1,12.1-180.8,82.7-287-8C-24.7,372.9,3.2,229.7,5.5,219z" />
                        </svg>
                    </State>
                </button>
            }

            {item.class !== 'hamburger' &&
                <Link to={item.route} activeClassName='is-active' className="menu-link">
                    <Icon icon={item.icon}></Icon>
                    <Label>
                        <Text small>{item.title}</Text>
                    </Label>
                    <State>
                        <svg x="0px" y="0px" width="95%" height="95%" viewBox="0 0 500 500">
                            <path d="M5.5,219C29.2,106.8,130.4-1.3,250.5,0c165.3,1.8,283.9,209.8,239,339c-25.5,73.3-99.1,108.7-131,124 c-25.1,12.1-180.8,82.7-287-8C-24.7,372.9,3.2,229.7,5.5,219z" />
                        </svg>
                    </State>
                </Link>
            }
        </Item>
    )
}

export default (props) => {
    const [visibility, setVisibility] = useState('')
    const [toggled, setToggle] = useState('')

    useEffect(() => {
        const hamburger = document.getElementsByClassName("hamburger")
        const body = document.documentElement
        const menuItems = document.getElementsByClassName("menu-link")
        const overlay = document.getElementsByClassName("menu-overlay")
        const venue = document.querySelector(".header-venue")
        const logo = document.querySelector(".page-logo")
        const menu = document.getElementsByClassName('main-menu')
        const burger = document.getElementsByClassName('burger')

        hamburger[0].addEventListener("click", function () {
            setToggle('is-active')
            setVisibility('')

            body.classList.add('u-oh')
            overlay[0].classList.add('is-active')
        })

        overlay[0].addEventListener("click", function () {
            setToggle('')

            body.classList.remove('u-oh')
            overlay[0].classList.remove('is-active')
        })

        for (var i = 0; i < menuItems.length; i++) {
            menuItems[i].addEventListener("click", function () {
                setToggle('')

                body.classList.remove('u-oh')
                overlay[0].classList.remove('is-active')
                menu[0].classList.remove('is-visible')

                if (burger[0]){
                    burger[0].classList.remove('true')
                }
            })
        }

        const onScroll = () => {
            const pageYOffset = window.pageYOffset
            const windowHeight = window.innerHeight
            const windowSize = window.innerWidth

            if (toggled === '' && windowSize > 767) {

                if (pageYOffset > (0.075 * windowHeight)){
                    setVisibility('is-hidden')
                    venue.classList.add('is-hidden')
                    logo.classList.add('mini')
                } else {
                    setVisibility('')
                    venue.classList.remove('is-hidden')
                    logo.classList.remove('mini')
                }
            }
        }
        onScroll()

        document.addEventListener("scroll", onScroll)
        
        return () => {
            document.removeEventListener("scroll", onScroll)
        }
    }, [])

    return(
        <Menu {...props} className={'main-menu ' + visibility}>
            {nav.map(createItem)}
        </Menu>
    )
}